body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Inter', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body, html { 
  font-family: 'Inter', sans-serif;
}
input {
    max-width: 400px;
    min-width: 300px;
    padding: 8px 12px;
    margin: 8px;
    line-height: 24px;
    border-radius: 8px;
    border: 1px solid rgb(245, 245, 245);
    background-color: rgb(245, 245, 245);
    outline: none;
    font-size: 12px;
    box-shadow: none;
    box-sizing: border-box;
}

input:focus {
    border: 1px solid rgb(230, 230, 230);
    transition: linear 100ms;
    background-color: white;
    outline: none;
    box-shadow: 0 0 4px 0 #c3c3c3;
}

button {
    color: #FFF;
    background-color: rgb(25, 0, 255);
    border: 1px solid rgb(25, 0, 255);
    border-radius: 8px;
    line-height: 24px;
    font-size: 12px;
    box-shadow: none;
    max-width: 400px;
    min-width: 300px;
    padding: 8px 12px;
    margin: 8px;
    cursor: pointer;
    font-weight: 600;
    transition: ease-in 75ms
}

button:hover {
    transition: 100ms linear;
    box-shadow: inset 0 0 0 50px rgb(22, 1, 211);
}

th, td {
    text-align: left;
    padding: 8px;
}

tr:nth-child(even) {
    background-color: #f2f2f2;
}

button:disabled {
    color: #FFF;
    background-color: rgb(119, 119, 119);
    border: 1px solid rgb(119, 119, 119);
    border-radius: 8px;
    line-height: 24px;
    font-size: 12px;
    box-shadow: none;
    max-width: 400px;
    min-width: 300px;
    padding: 8px 12px;
    margin: 8px;
    cursor: not-allowed;
    font-weight: 600;
    transition: ease-in 75ms
}

::-webkit-scrollbar {
    display: none;
}

